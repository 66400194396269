import React from "react"
import { graphql } from "gatsby"

import Page from "../templates"
import SEO from "../templates/seo"
import InstaPost from "../components/InstaPost"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Aktuelles = ({ data }) => {
  return <div>not working</div>
  // console.log(data)
  // const instaPosts = data.allInstaNode.edges
  // instaPosts.sort((a, b) => b.node.timestamp - a.node.timestamp)
  // console.log(instaPosts[0].node.timestamp)
  // return (
  //   <Page>
  //     <SEO title="Aktuelles" />
  //     <h1>Aktuelles</h1>
  //     <p>Hier findst du Aktuelles aus meinem Atelier...</p>
  //     <Row className="form-row">
  //       {instaPosts.map((post, i) => (
  //         <Col xs={3} className="mb-2 instapost ">
  //           <InstaPost key={i} post={post} />

  //           {/* <span className="small">{post.node.caption}</span> */}
  //         </Col>
  //       ))}
  //     </Row>
  //   </Page>
  // )
}

export default Aktuelles

// export const pageQuery = graphql`
//   query {
//     allInstaNode {
//       edges {
//         node {
//           id
//           likes
//           comments
//           mediaType
//           preview
//           original
//           timestamp
//           caption
//           localFile {
//             childImageSharp {
//               fluid(maxHeight: 500, maxWidth: 500, quality: 50) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//           # Only available with the public api scraper
//           thumbnails {
//             src
//             config_width
//             config_height
//           }
//           dimensions {
//             height
//             width
//           }
//         }
//       }
//     }
//   }
// `
